window.Main = new (function () {

    var $windowWidth = $(window).width();
    var that = this;

    //Mobile functions
    let destructor = function(){
        $('.navSite').detach().appendTo('.DskNavBox');
        $('body').removeClass('noScroll');
    }

    let constructor = function(){
        $('.navSite').detach().prependTo('.tgNav');
    }

    this.matchesMenu = function(){
        let breackpoint = 1023,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        mediaqueryresponse(mql)

        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                constructor();
            } else {
                destructor();
            }
        }
    }

    //open-close menu
    this.menuToggle = function() {
        $('body').toggleClass('noScroll');
    }

    // Selecciona todas las imágenes con la clase 'lazy'
    const lazyImages = document.querySelectorAll('img.lazy');

    // Configura el Observer
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.dataset.src;
                img.classList.remove('lazy');  // Remueve la clase lazy
                observer.unobserve(img);       // Detiene la observación de la imagen
            }
        });
    });

    /**
     * Init
     */
    this.init = function () {

        //mobile menu
        that.matchesMenu();

        $('.hamburger').on('click', that.menuToggle);

        //$('.tooltip').tooltip();
        //if($windowWidth < 991) {

            try {
                $('.slick-products').slick({
                    dots: true,
                    prevArrow: null,
                    nextArrow: null,
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 1399,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            } catch (e) {
                console.log(e);
            }


        //}

        //that.headerClass();

        $('.goTo').on('click', function(event) {
            event.preventDefault();
            let sectionToGo = $(this).data('goto');
            $('header .DskNavBox nav li a').removeClass('active');
            $(this).addClass('active');

            $('html, body').animate({
                scrollTop: $('#' + sectionToGo).offset().top
            }, 800); // 800 es la duración de la animación en milisegundos
        });

        // Aplica el Observer a cada imagen
        lazyImages.forEach(img => {
            observer.observe(img);
        });

        document.addEventListener("DOMContentLoaded", function() {
            const lazyImages = document.querySelectorAll('img.lazy-load');
            lazyImages.forEach(img => {
                img.src = img.getAttribute('data-src');
                img.removeAttribute('data-src');
            });
        });

        document.querySelectorAll('a[target="_blank"]').forEach(link => {
            link.setAttribute('rel', 'nofollow noopener');
        });

    };

    this.unSlick = function () {
        try {
            $('.slick-products').slick('unslick');
        } catch (e) {
            console.log(e);
        }

    };

    //add class to header on scroll
    this.headerClass = function(){
        $(window).scroll(function () {
            if ($(window).scrollTop() > 15) {
                $('header').addClass('stick')
            } else {
                $('header').removeClass('stick')
            }
            if( $('body').hasClass('is_team') ) {
                $('header').addClass('stick');
            }
        })
    }

})();

$(window).resize(function(){
    var $windowWidth = $(window).width();
    if ($windowWidth > 991) {
        //Main.unSlick();
    } else {
        //Main.init();
    }
});
